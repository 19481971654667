var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('v-btn',{staticClass:"mb-9",attrs:{"outlined":"","color":"success"},on:{"click":_vm.openModalCreate}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","show-extra":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mt-4 mb-4"},[_c('UiTable',{attrs:{"data":[row],"config":_vm.tableExtraConfig}})],1)]}},{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.openModalEdit(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"payInLimit",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.payInLimit.min)+" – "+_vm._s(row.payInLimit.max)+" ")]}},{key:"payInFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption mt-1 mb-1",staticStyle:{"line-height":"1.1"}},[_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Absolute:'))+" ")]),_vm._v(_vm._s(row.payInFee.abs))]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Percent:'))+" ")]),_vm._v(_vm._s(row.payInFee.rel)+"%")]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Max fee'))+": ")]),_vm._v(_vm._s(row.payInFee.max))]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Min fee'))+": ")]),_vm._v(_vm._s(row.payInFee.min))])])]}},{key:"payOutLimit",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.payOutLimit.min)+" – "+_vm._s(row.payOutLimit.max)+" ")]}},{key:"payOutFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption mt-1 mb-1",staticStyle:{"line-height":"1.1"}},[_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Absolute:'))+" ")]),_vm._v(_vm._s(row.payOutFee.abs))]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Percent:'))+" ")]),_vm._v(_vm._s(row.payOutFee.rel)+"%")]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Max fee'))+": ")]),_vm._v(_vm._s(row.payOutFee.max))]),_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Min fee'))+": ")]),_vm._v(_vm._s(row.payOutFee.min))])])]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editingModal.data,"is-creating":_vm.editingModal.isCreating},on:{"apply":_vm.apply},model:{value:(_vm.editingModal.show),callback:function ($$v) {_vm.$set(_vm.editingModal, "show", $$v)},expression:"editingModal.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }