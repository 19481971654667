export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Title',
    },
    cell: {
      type: 'text',
      value: (e) => e.title,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'text',
      value: (e) => e.name,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Decimal',
    },
    cell: {
      type: 'text',
      value: (e) => e.decimal,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Enabled',
    },
    cell: {
      type: 'slot',
      name: 'isEnabled',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Deleted',
    },
    cell: {
      type: 'slot',
      name: 'isDeleted',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Payment methods',
    },
    cell: {
      type: 'slot',
      name: 'paymentMethods',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
