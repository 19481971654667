export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Details',
    },
    cell: {
      type: 'text',
      value: (e) => e.details,
      tooltip: true,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Token',
    },
    cell: {
      type: 'text',
      value: (e) => e.token,
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'PayIn',
    },
    cell: {
      type: 'text',
      value: (e) => e.payIn,
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'PayOut',
    },
    cell: {
      type: 'text',
      value: (e) => e.payOut,
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Tag',
    },
    cell: {
      type: 'text',
      value: (e) => e.tag,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Tag Regex',
    },
    cell: {
      type: 'text',
      value: (e) => e.tagRegex,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Receiver Regex',
    },
    cell: {
      type: 'text',
      value: (e) => e.receiverRegex,
    },
  },
];
