//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      input: {
        payInFee: {},
        payOutFee: {},
        payInLimit: {},
        payOutLimit: {},
      },
    };
  },

  computed: {
    ...mapState('tradersCurrencies', {
      currencies: 'data',
    }),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        name: '',
        details: '',
        type: 'TxMethod_Crypto',
        currency: '',
        payIn: false,
        payOut: false,
        tag: false,
        receiverRegex: '',
        tagRegex: '',
        payInFee: {
          abs: 0,
          rel: 0,
          min: 0,
          minClear: false,
          max: 0,
          maxClear: false,
        },
        payOutFee: {
          abs: 0,
          rel: 0,
          min: 0,
          minClear: false,
          max: 0,
          maxClear: false,
        },
        payInLimit: {
          min: 0,
          minClear: false,
          max: 0,
          maxClear: false,
        },
        payOutLimit: {
          min: 0,
          minClear: false,
          max: 0,
          maxClear: false,
        },
        network: '',
        token: '',
      };
    },
  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
        } else {
          this.$set(this, 'input', data);
        }
      }
    },
  },

  methods: {
    // remove() {
    //   this.$emit('remove');
    // },

    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
  },
};
